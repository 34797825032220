const initialState = {
  userData: JSON.parse(localStorage.getItem('userData')),
  branchId: ''
}
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.data?.user }
    case 'UPDATE_PROFILE':
      return { ...state, userData: action.data }
    case 'UPDATE_TWOFA':
      return { ...state, userData: action.data }
    case 'LOGOUT':
      return { ...state, userData: {} }
    case 'BRANCH':
      return { ...state, branchId: action.data }
    default:
      return state
  }
}

export default authReducer